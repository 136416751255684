export const Input = {
  width: '100%',
  fontSize: '0.85em',
  borderRadius: '4px',
  position: 'relative',
  padding: '1em',
  marginTop: '4px',
  transition: '0.35s ease-in-out',
  border: '1px solid $neutral30',

  '&::placeholder': {
    color: '$interfaceLabelSecondary'
  },

  '&:focus': {
    borderColor: '$neutral100',
    outline: 'none'
  },

  '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
    '-webkit-appearance': 'none',
    margin: 0
  },

  '&[type="number"]': {
    '-moz-appearance': 'textfield'
  },

  variants: {
    error: {
      true: {
        border: '1px solid $semanticErrorText'
      },
      false: {
        border: '1px solid $neutral30'
      }
    }
  },

  defaultVariants: {
    error: 'false'
  }
}
