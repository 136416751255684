import { IRepositoryRequirements } from '../../repositories.common'
import { Fetch } from 'utils'

function DigitalCouponsRepository ({ authentication, baseUrl }: IRepositoryRequirements) {
  const url = `${baseUrl}/digital-coupons`

  function associateCampaign (vendorAccountId: string) {
    return Fetch.post({ url: `${url}/associate`, data: { vendorAccountId }, config: { headers: authentication } })
  }

  return {
    associateCampaign
  }
}

export default DigitalCouponsRepository
