import { useSidebar } from 'admin-portal-shared-services'
import { ElementType } from 'react'

export type SidebarProps = {
  id: string;
  title: string;
  path: string;
  icon: ElementType;
}

export function Sidebar (items: SidebarProps[], utils: SidebarProps[]) {
  useSidebar({
    items,
    utils
  })
}
