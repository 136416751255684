import { IRepositoryRequirements } from 'repositories/repositories.common'
import { Fetch } from 'utils'
import { InvoiceCreateProps } from './Invoice.props'

export function InvoiceRepository ({ baseUrl, authentication }: IRepositoryRequirements) {
  const url = `${baseUrl}/invoices`

  function create (data: InvoiceCreateProps) {
    return Fetch.post({ url, data, config: { headers: authentication } })
  }

  return {
    create
  }
}
