import { Fetch } from 'utils'
import { getFeaturesMapper } from './Configs.mapper'
import { GetStoresDTO } from './Configs.dto'

export function ConfigsRepository (baseUrl: string) {
  const basePath = `${baseUrl}/configs`

  async function getFeatures (zone: string, vendorid: string) {
    return Fetch.get({ url: `${basePath}/features`, config: { headers: { zone, vendorid } } }).then(({ data }) =>
      getFeaturesMapper(data.data)
    )
  }

  async function getStores (zone: string, vendorId: string) {
    return Fetch.get({
      url: `${basePath}/stores/vendor?vendorId=${vendorId}&zone=${zone}`
    }).then(({ data }) => data.data as GetStoresDTO[])
  }

  return {
    getFeatures,
    getStores
  }
}
