import { styled } from '@hexa-ui/theme'
import { Grid, Alert as HubAlert } from '@hexa-ui/components'

export const Alert = styled(HubAlert, {
  width: '100% !important',
  whiteSpace: 'pre-wrap'
})

export const DialogCloseBtn = styled('div', {
  display: 'flex',
  justifyContent: 'flex-end'
})

export const DialogContent = styled('div', {
  whiteSpace: 'pre-line',
  margin: '15px 0'
})

export const ContainerStyled = styled(Grid.Container, {
  '& div[role="dialog"]': {
    maxWidth: '550px'
  }
})

export const ModalTitleStyle = styled('div', {
  '& h1': {
    margin: '0 !important'
  }
})

export const Gap = styled('div', {
  '> :not(:last-child)': {
    marginBottom: '$4'
  }
})

export const MoreInfoJsonStyle = styled('div', {
  marginTop: 20,
  backgroundColor: '#F5F5F5',
  borderRadius: 8,
  padding: '0 10px 10px',
  fontSize: '0.8em',

  '& h1': {
    width: '100%',
    textAlign: 'center',
    margin: 0,
    fontSize: 15
  },

  '& .react-json-view': {
    lineHeight: '15px'
  }
})
