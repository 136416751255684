import React from 'react'
import { SettingsProps, SettingsActions } from './Settings.props'
import { SettingsBootstrap } from './Settings.bootstrap'
import { Loading } from 'components'
import { Error500 } from '@hexa-ui/components'

export const SettingsContext = React.createContext<{
  state: SettingsProps;
  dispatch: React.Dispatch<SettingsActions>;
}>({ state: {} as SettingsProps, dispatch: () => undefined })

interface SettingsProviderProps {
  children: React.ReactElement;
  onUpdate?: (settings: SettingsProps) => void;
}

export function SettingsProvider (props: SettingsProviderProps) {
  const { state, status, dispatch } = SettingsBootstrap()

  React.useEffect(() => {
    props.onUpdate && props.onUpdate(state)
  }, [state, props])

  return (
    <SettingsContext.Provider value={{ state, dispatch }}>
      {status === 'ERROR' && <Error500 headerText="Ooops! Something went wrong" />}
      {status === 'LOADING' && <Loading fullHeight />}
      {status === 'LOADED' && props.children}
    </SettingsContext.Provider>
  )
}
