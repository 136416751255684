import { styled } from '@hexa-ui/theme'

export const Container = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',

  variants: {
    fullHeight: {
      true: {
        height: '90vh'
      },
      false: {
        height: 'max-content'
      }
    }
  },

  defaultVariants: {
    fullHeight: false
  }
})

export const TextContainer = styled('span', {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: '$8',

  '*': {
    margin: '0'
  }
})
