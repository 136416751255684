import { ElementType, ComponentPropsWithRef } from 'react'

export const HomeIcon = (props: ElementType<ComponentPropsWithRef<'svg'>> | { onClick: () => void }) => (
  <svg width="16" height="16" viewBox="0 0 18 18" fill="inherit" xmlns="http://www.w3.org/2000/svg">
    <mask id="mask0_507_68" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="0" width="18" height="18">
      <path
        d="M3.20455 18C1.84894 18 0.75 16.9011 0.75 15.5455V6.54545C0.75 6.29297 0.866569 6.05463 1.06587 5.89962L8.4295 0.172348C8.72496 -0.0574494 9.13868 -0.0574494 9.43413 0.172348L16.7978 5.89962C16.9971 6.05463 17.1136 6.29297 17.1136 6.54545V15.5455C17.1136 16.9011 16.0147 18 14.6591 18H3.20455ZM8.93182 1.854L2.38636 6.94473V15.5455C2.38636 15.965 2.70221 16.3109 3.10913 16.3581L3.20455 16.3636L5.65875 16.3635L5.65909 9C5.65909 8.58041 5.97494 8.23458 6.38186 8.18732L6.47727 8.18182H11.3864C11.806 8.18182 12.1518 8.49767 12.199 8.90458L12.2045 9L12.2044 16.3635L14.6591 16.3636C15.0913 16.3636 15.4453 16.0285 15.4752 15.6039L15.4773 15.5455V6.94555L8.93182 1.854ZM10.5682 9.81818H7.29545L7.29525 16.3635H10.5679L10.5682 9.81818Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0_507_68)">
      <path d="M21 -3H-3V21H21V-3Z" fill="inherit" />
    </g>
  </svg>
)

export default HomeIcon
