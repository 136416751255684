import { AlertOctagon } from '@hexa-ui/icons'
import { styled } from '@hexa-ui/theme'
import { Input } from 'contexts/Theme/mixins'
import 'react-datepicker/dist/react-datepicker.css'

export const Wrapper = styled('div', {
  width: '100%',
  margin: 0,

  '.react-datepicker__input-container input': {
    ...Input,
    cursor: 'pointer',
    marginTop: '0',
    borderRadius: '$2',
    fontFamily: '$paragraph',
    width: '100%',
    lineHeight: '$6',
    fontWeight: '$normal',
    color: '$interfaceLabelPrimary',
    height: '2.5rem',
    fontSize: '$1'
  },

  '.react-datepicker__day--selected, .react-datepicker__day--range-end, .react-datepicker__day--range-start': {
    color: '$neutral100 !important',
    background: '$brandPrimaryBasis !important'
  },

  '.react-datepicker__day--in-range, .react-datepicker__day--in-selecting-range': {
    color: '$neutral100',
    background: '$brandPrimaryBackground'
  }
})

export const Label = styled('label', {
  position: 'initial',
  fontFamily: '$paragraph',
  fontSize: '$2',
  lineHeight: '$3',
  fontWeight: '$semibold',
  color: '$interfaceLabelPrimary',
  variants: {
    size: {
      small: {
        fontSize: '$2',
        lineHeight: '$3',
        fontWeight: '$semibold'
      },
      large: {
        fontSize: '$2',
        lineHeight: '$3',
        fontWeight: '$semibold'
      }
    },
    hasError: {
      true: {
        color: '$semanticErrorText'
      }
    },
    disabled: {
      true: {
        color: '$interfaceLabelSecondary'
      }
    }
  }
})

export const LabelContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  paddingBottom: '$1'
})

export const OptionalIndicator = styled('div', {
  fontFamily: '$paragraph',
  color: '$interfaceLabelSecondary',
  fontSize: '$2',
  lineHeight: '$3',
  fontWeight: '$normal',
  paddingLeft: '$1'
})

export const Error = styled('div', {
  fontFamily: '$paragraph',
  fontSize: '$2',
  lineHeight: '$4',
  fontWeight: '$normal',
  color: '$semanticErrorText',
  paddingBottom: '$1',
  display: 'flex',
  alignItems: 'center'
})

export const ErrorIcon = styled(AlertOctagon, {
  '&>svg': {
    fill: '$semanticErrorText'
  },
  marginRight: '$1',
  verticalAlign: 'top'
})
