import { IRepositoryRequirements } from 'repositories/repositories.common'
import { Fetch } from 'utils'
import { CreateShoppingListProps } from './ShoppingList.props'

const ShoppingListRepository = ({ baseUrl, authentication }: IRepositoryRequirements) => {
  const url = `${baseUrl}/shopping-list`

  const create = async (data: CreateShoppingListProps) => {
    return Fetch.post({ url, data, config: { headers: authentication } })
  }

  return { create }
}

export default ShoppingListRepository
