import { ModularPopulatorResult } from './Populator.DTO'

export function toModularPopulatorResult ({ data }: any): ModularPopulatorResult {
  return {
    operations: data.operations.map((operation: any) => ({
      operation: operation.operation,
      status: operation.status,
      input: operation.input,
      output: operation.output
    }))
  }
}
