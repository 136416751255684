/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useContext, useEffect, useMemo } from 'react'
import { SettingsContext } from 'contexts/Settings'
import { useAuthenticationService } from 'admin-portal-shared-services'
import { genid, Fetch } from 'utils'
import { useFeedbackDialog, useNotify } from 'contexts'
import {
  DealsRepository,
  AccountRepository,
  OrdersRepository,
  ProductRepository,
  EmptiesRepository,
  CombosRepository,
  InvoiceRepository,
  CatalogsRepository,
  RecommendationsRepository,
  RewardsRepository,
  PopulatorRepository,
  DeliveryCenterRepository,
  ChargesRepository,
  WishlistRepository,
  ConfigsRepository,
  DigitalCouponsRepository,
  SearchRepository,
  ShoppingListRepository
} from 'repositories'

function getRequestTraceId (email: string, zone: string, environment: string) {
  const username = email.split('@')[0]
  const domain = `DataGen.${zone}.${environment}`
  const hash = genid.generate()

  return {
    username,
    domain,
    hash,
    trace: `${username}@${domain}.${hash}`
  }
}

function createNewRelicUrl (requestTraceId: string, env: 'SIT' | 'UAT') {
  const baseUrl =
    'https://one.newrelic.com/launcher/logger.log-launcher?platform[timeRange][duration]=604800000&platform[$isFallbackTimeRange]=false&launcher='
  const params = {
    isEntitled: true,
    activeView: 'View All Logs',
    query: `requestTraceId:"*${requestTraceId}*" entity.name:"data-brew-server-${env.toLowerCase()}"`,
    eventTypes: ['Log']
  }

  const convertedParams = btoa(JSON.stringify(params))

  return `${baseUrl}${convertedParams}`
}

let ALREADY_HAS_AXIOS_INTERCEPTOR = false

export function useRepository () {
  const { state } = useContext(SettingsContext)
  const { requestError, requestTokenError } = useFeedbackDialog()
  const authService = useAuthenticationService()
  const notify = useNotify()

  const authentication = useMemo(
    () => ({
      Authorization: authService.getAuthHeader() || '',
      vendorId: state.vendor,
      zone: state.zone
    }),
    [state.vendor, state.zone, authService]
  )

  useEffect(() => {
    if (ALREADY_HAS_AXIOS_INTERCEPTOR) return

    ALREADY_HAS_AXIOS_INTERCEPTOR = true

    const requestTraceId = getRequestTraceId(authService.getUserEmailB2C(), state.zone, state.environment)

    const notification = () => {
      const fullRequestTraceId = `
        ${requestTraceId.username}@${requestTraceId.domain}.${requestTraceId.hash}
      `

      notify.alert(String(`Your RequestTraceId is: ${fullRequestTraceId}`), [
        {
          label: 'Copy',
          onClick: () => navigator.clipboard.writeText(requestTraceId.trace)
        },
        {
          label: 'Open in New Relic',
          onClick: () => window.open(createNewRelicUrl(requestTraceId.trace, state.environment), '_blank')
        }
      ])
      // eslint-disable-next-line no-console
      console.log(`Your RequestTraceId: ${requestTraceId.trace}`)
    }

    const Request = Fetch.instance.interceptors.request.use(
      (config) => {
        if (config.method === 'post' || config.method === 'put') {
          config.headers.requestTraceId = requestTraceId.trace
        }
        return config
      },
      (error) => Promise.reject(error)
    )

    const Response = Fetch.instance.interceptors.response.use(
      (axios) => {
        if (axios.config.method === 'post' || axios.config.method === 'put') {
          notification()
        }
        return axios
      },
      (error) => {
        if (error.response && error.response.status === 401) {
          requestTokenError()
          return Promise.reject(error)
        }

        requestError(error.response.data ?? error.response, requestTraceId.trace)
        return Promise.reject(error)
      }
    )

    return () => {
      Fetch.instance.interceptors.request.eject(Request)
      Fetch.instance.interceptors.response.eject(Response)
      ALREADY_HAS_AXIOS_INTERCEPTOR = false
    }
  }, [ALREADY_HAS_AXIOS_INTERCEPTOR])

  return {
    deals: useCallback(
      () => DealsRepository({ baseUrl: state.baseUrl, authentication }),
      [state.baseUrl, authentication]
    )(),
    account: useCallback(
      () => AccountRepository({ baseUrl: state.baseUrl, authentication }),
      [state.baseUrl, authentication]
    )(),
    orders: useCallback(
      () => OrdersRepository({ baseUrl: state.baseUrl, authentication }),
      [state.baseUrl, authentication]
    )(),
    products: useCallback(
      () => ProductRepository({ baseUrl: state.baseUrl, authentication }),
      [state.baseUrl, authentication]
    )(),
    empties: useCallback(
      () => EmptiesRepository({ baseUrl: state.baseUrl, authentication }),
      [state.baseUrl, authentication]
    )(),
    combos: useCallback(
      () => CombosRepository({ baseUrl: state.baseUrl, authentication }),
      [state.baseUrl, authentication]
    )(),
    catalogs: useCallback(
      () => CatalogsRepository({ baseUrl: state.baseUrl, authentication }),
      [state.baseUrl, authentication]
    )(),
    invoice: useCallback(
      () => InvoiceRepository({ baseUrl: state.baseUrl, authentication }),
      [state.baseUrl, authentication]
    )(),
    recommendations: useCallback(
      () => RecommendationsRepository({ baseUrl: state.baseUrl, authentication }),
      [state.baseUrl, authentication]
    )(),
    rewards: useCallback(
      () => RewardsRepository({ baseUrl: state.baseUrl, authentication }),
      [state.baseUrl, authentication]
    )(),
    populator: useCallback(
      () => PopulatorRepository({ baseUrl: state.baseUrl, authentication }),
      [state.baseUrl, authentication]
    )(),
    deliveryCenter: useCallback(
      () => DeliveryCenterRepository({ baseUrl: state.baseUrl, authentication }),
      [state.baseUrl, authentication]
    )(),
    charges: useCallback(
      () => ChargesRepository({ baseUrl: state.baseUrl, authentication }),
      [state.baseUrl, authentication]
    )(),
    wishlist: useCallback(
      () => WishlistRepository({ baseUrl: state.baseUrl, authentication }),
      [state.baseUrl, authentication]
    )(),
    shoppingList: useCallback(
      () => ShoppingListRepository({ baseUrl: state.baseUrl, authentication }),
      [state.baseUrl, authentication]
    )(),
    configs: useCallback(() => ConfigsRepository(state.baseUrl), [state.baseUrl])(),
    digitalCoupons: useCallback(
      () => DigitalCouponsRepository({ baseUrl: state.baseUrl, authentication }),
      [state.baseUrl, authentication]
    )(),
    search: useCallback(
      () => SearchRepository({ baseUrl: state.baseUrl, authentication }),
      [state.baseUrl, authentication]
    )()
  }
}
