import { Breadcrumb as HubBreadcrumb } from '@hexa-ui/components'
import { styled } from '@hexa-ui/theme'
import { Link } from 'react-router-dom'

type BreadcrumbProps = {
  path: string[];
}

export function Breadcrumb (props: BreadcrumbProps) {
  return (
    <HubBreadcrumb.Root>
      <HubBreadcrumb.HomeItem asChild>
        <CustomLink to="*">Home</CustomLink>
      </HubBreadcrumb.HomeItem>
      {props.path.map((item, index) => (
        <HubBreadcrumb.Item key={index} isCurrentPage={index + 1 === props.path.length} asChild>
          <CustomLink to={`/${props.path.slice(0, index + 1).join('/')}`}>{item.replace(/_/g, ' ')}</CustomLink>
        </HubBreadcrumb.Item>
      ))}
    </HubBreadcrumb.Root>
  )
}

const CustomLink = styled(Link, {
  textTransform: 'capitalize !important',
  cursor: 'pointer',
  whiteSpace: 'nowrap !important'
})
