import DatePickerComponent from 'react-datepicker'
import * as S from './DatePicker.style'
import { DatePickerProps } from './DataPicker.props'

const DatePicker = (props: DatePickerProps) => {
  return (
    <S.Wrapper data-testid="datepicker">
      {props.label
        ? (
        <S.LabelContainer>
          <S.Label hasError={!!props.error} disabled={props.disabled}>
            {props.label}
          </S.Label>
          {!props.required ? <S.OptionalIndicator>(Optional)</S.OptionalIndicator> : null}
        </S.LabelContainer>
          )
        : null}

      {props.error
        ? (
        <S.Error>
          <S.ErrorIcon />
          {props.error}
        </S.Error>
          )
        : null}

      <DatePickerComponent
        id={String(props.id)}
        selected={props.selected}
        startDate={props.startDate}
        endDate={props.endDate}
        disabledKeyboardNavigation={true}
        selectsRange={props.isDateRange ? true : undefined}
        selectsMultiple={undefined}
        onChange={(date: any) => props.onChange?.(date)}
        placeholderText={props.placeholder}
        autoComplete="off"
        minDate={props.minDate}
        disabled={props.disabled}
        dateFormat="dd/MM/yyyy"
      />
    </S.Wrapper>
  )
}

export default DatePicker
