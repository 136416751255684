import React from 'react'
import { DialogProps, DialogsProps } from './Dialog.props'
import {
  DialogCloseBtn,
  DialogContent,
  ContainerStyled,
  Alert,
  ModalTitleStyle,
  MoreInfoJsonStyle,
  Gap
} from './Dialog.style'
import { Dialog, Heading, Button } from '@hexa-ui/components'
import ReactJson from 'react-json-view'

const Acknowledgement = (dialog: DialogProps) => (
  <Dialog.Root
    onClose={() => dialog.onClose(dialog.id)}
    closeButton={dialog.showCloseIcon || true}
    open={true}
    title={
      <ModalTitleStyle>
        <Heading onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
          {dialog.title}
        </Heading>
      </ModalTitleStyle>
    }
    actions={
      <DialogCloseBtn>
        <Dialog.Close>
          <Button size="medium" variant="primary" onClick={() => dialog.onClose(dialog.id)}>
            {dialog.closeText || 'Close'}
          </Button>
        </Dialog.Close>
      </DialogCloseBtn>
    }
  >
    <Gap>
      <DialogContent>{dialog.message}</DialogContent>
      {dialog.alertMessage && <Alert message={dialog.alertMessage} type={dialog.alertType} />}
      {dialog.requestTraceId && <Alert message={`Request Trace ID: ${dialog.requestTraceId}`} type="info" />}
      {dialog.moreInfoJson && (
        <MoreInfoJsonStyle>
          <Heading onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
            More info:
          </Heading>
          <ReactJson
            src={dialog.moreInfoJson}
            collapsed={false}
            displayDataTypes={false}
            quotesOnKeys={false}
            name={false}
          />
        </MoreInfoJsonStyle>
      )}
    </Gap>
  </Dialog.Root>
)

const DialogComp = (props: DialogsProps) => {
  return (
    <ContainerStyled data-testid="dialog-container">
      {props.dialogs.map((dialog) => (
        <React.Fragment key={dialog.id}>
          {dialog.type === 'acknowledgement' && <Acknowledgement {...dialog} />}
        </React.Fragment>
      ))}
    </ContainerStyled>
  )
}

export default DialogComp
