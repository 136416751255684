import { styled } from '@hexa-ui/theme'
import { ChevronDown } from 'assets/icons'

export const Container = styled('div', {
  width: '100%',
  padding: '16px 0',
  '& > :nth-child(2)': {
    marginTop: '12px'
  }
})

export const Header = styled('div', {
  width: 'inherit',
  display: 'flex',
  alignItems: 'center',
  paddingBottom: '16px',
  borderBottom: '1px solid',
  borderBottomColor: '$neutral30',

  variants: {
    disableClick: {
      true: {
        cursor: 'default'
      },
      false: {
        cursor: 'pointer'
      }
    }
  },

  defaultVariants: {
    disableClick: 'false'
  }
})

export const TitleContainer = styled('span', {
  display: 'flex',
  alignItems: 'center',
  flex: 1,

  svg: {
    width: '14px',
    height: '14px',
    cursor: 'pointer',
    color: '$semanticInfoText'
  },

  h4: {
    padding: 0,
    fontSize: '1.15rem',
    margin: '0 16px 0 0'
  },

  p: {
    fontSize: '0.85rem',
    padding: 0,
    margin: 0,
    color: '$neutral70'
  }
})

export const Body = styled('div', {})

export const Icon = styled(ChevronDown, {
  width: '14px',
  transition: '0.35s ease-in-out',
  fill: '$semanticInfoText',

  variants: {
    open: {
      true: {
        transform: 'rotate(-180deg)'
      },
      false: {
        transform: 'inherit'
      }
    }
  },

  defaultVariants: {
    open: 'false'
  }
})

export const AccordionGroup = styled('div', {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  borderBottom: 'none',
  borderRadius: '8px'
})
