import { CoverageAreasDTO } from './DeliveryCenter.DTO'

export function toCoverageAreasDTO (data: any[]): CoverageAreasDTO[] {
  return data.map(
    (item: any) =>
      ({
        id: item.accountsDCId,
        city: item.city,
        state: item.state
      }) as CoverageAreasDTO
  )
}
