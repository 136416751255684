import { Toggle as HubToggle } from '@hexa-ui/components'
import { styled } from '@hexa-ui/theme'

const StyledContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  variants: {
    isLineCentered: {
      true: {
        marginBottom: '0.7rem'
      }
    },
    nearToRefInput: {
      true: {
        marginTop: '-0.5rem'
      }
    }
  }
})

const StyledLabel = styled('label', {
  fontWeight: '$semibold',
  fontSize: '0.8rem'
})

const StyledHintText = styled('p', {
  fontSize: '0.75rem',
  margin: 0
})

const StyledTextContainer = styled('span', {
  marginLeft: '$2'
})

interface IFormToggle {
  label: string;
  onCheck: () => void;
  checked?: boolean;
  id?: string;
  hintText?: string;
  isLineCentered?: boolean;
  nearToRefInput?: boolean;
}

export function Toggle (props: IFormToggle) {
  return (
    <StyledContainer {...props}>
      <HubToggle
        id={`rbt_${props.id}`}
        checked={props.checked}
        onCheckedChange={props.onCheck}
        placeholder={undefined}
        onPointerEnterCapture={undefined}
        onPointerLeaveCapture={undefined}
      />
      <StyledTextContainer>
        <StyledLabel>{props.label}</StyledLabel>
        {props.hintText && <StyledHintText>{props.hintText}</StyledHintText>}
      </StyledTextContainer>
    </StyledContainer>
  )
}
