import React from 'react'
import ReactDOM from 'react-dom'
import SingleSpa from 'single-spa-react'
import { useLogService } from 'admin-portal-shared-services'
import AppMount from './App'

const AppMountBoundary = (_error: Error, _info: any, _props: any) => {
  useLogService().error(_error)
  return <p>An error has occurred, try later</p>
}

const reactLifeCycles = SingleSpa({
  React,
  ReactDOM,
  rootComponent: AppMount,
  errorBoundary: AppMountBoundary
})

export const { bootstrap, mount, unmount } = reactLifeCycles
