import { GlobalProvider } from 'contexts'
import { Router } from 'routes/Router'

type AppProps = {
  segmentKey?: string;
}

export const App = (props: AppProps) => {
  return (
    <GlobalProvider segmentKey={props.segmentKey}>
      <Router />
    </GlobalProvider>
  )
}

export default App
