import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { styled } from '@hexa-ui/theme'
import { Grid, IconButton } from '@hexa-ui/components'
import { ChevronLeft } from '@hexa-ui/icons'
import { Sidebar, SidebarProps } from './components/Sidebar'
import { FeatureSelect } from './components/FeatureSelect'
import { Breadcrumb } from './components/Breadcrumb'
import { Header } from './components/Header'

export type HubProps = {
  sidebar: SidebarProps[];
  utils: SidebarProps[];
  children: React.ReactNode;
}

export function Hub (props: HubProps) {
  Sidebar(props.sidebar, props.utils || [])

  const { pathname } = useLocation()
  const history = useHistory()

  const path = pathname.split('/').filter((path) => path.length !== 0)
  const sidebarItems = [...props.sidebar, ...props.utils]
  const currentItem = sidebarItems.find((item) => item.id === path[0])
  const hasCategory = path.length > 2

  return (
    <Container sidebar type="fluid">
      <Actions>
        <Breadcrumb path={path} />
        <IconButton
          id="return"
          size="small"
          variant="tertiary"
          icon={ChevronLeft}
          onClick={() => history.push('/' + path.slice(0, -1).join('/'))}
        />
        <FeatureSelect />
      </Actions>
      <Header pageTitle={currentItem?.title || path[0]} pageCategory={(hasCategory && path[1]) || undefined} />
      <Content>{props.children}</Content>
    </Container>
  )
}

const Container = styled(Grid.Container, {
  '@desktopXlarge': {
    width: '100%',
    maxWidth: '1440px',
    margin: 'auto !important'
  }
})

const Actions = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  marginTop: '$4',
  marginBottom: '$4',

  '#return': {
    '@desktopLarge': {
      display: 'none'
    }
  },

  nav: {
    display: 'none',

    '@desktopLarge': {
      display: 'block'
    }
  }
})

const Content = styled('div', {
  width: '100%',
  marginTop: '$4',
  marginBottom: '80px'
})
