import { Select } from '@hexa-ui/components'
import { World, Store } from '@hexa-ui/icons'
import { styled } from '@hexa-ui/theme'
import { SelectWrapper } from 'components/Form'
import { useSettings } from 'contexts'

export function FeatureSelect () {
  const { getSettings, setSettings } = useSettings()

  const zones = getSettings.availableZones.map((zone) => ({
    name: zone.name,
    value: zone.code
  }))

  const vendors = getSettings.availableVendors[getSettings.zone].map((vendor) => ({
    name: vendor.name,
    value: vendor.value
  }))

  return (
    <Features id="featureSelect">
      <SelectWrapper>
        <Select.Root
          id="zoneSelector"
          icon={<World />}
          value={getSettings.zone}
          size="small"
          shape="pill"
          onChange={(zone) => setSettings({ zone })}
        >
          {zones.map(({ name, value }) => (
            <Select.Option key={value} value={value}>
              {`${value} - ${name}`}
            </Select.Option>
          ))}
        </Select.Root>
      </SelectWrapper>
      <SelectWrapper>
        <Select.Root
          id="vendorSelector"
          icon={<Store />}
          value={getSettings.vendor}
          size="small"
          shape="pill"
          onChange={(vendor) => setSettings({ vendor })}
        >
          {vendors.map(({ name, value }) => (
            <Select.Option key={value} value={value}>
              {name}
            </Select.Option>
          ))}
        </Select.Root>
      </SelectWrapper>
    </Features>
  )
}

const Features = styled('div', {
  display: 'flex',
  '> :not(:last-child)': {
    marginRight: '$2'
  }
})
