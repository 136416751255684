import { Heading, IconButton, Dialog, Paragraph } from '@hexa-ui/components'
import { styled } from '@hexa-ui/theme'
import { Info } from '@hexa-ui/icons'
import { useSettings } from 'contexts'

type HeaderProps = {
  pageTitle: string;
  pageCategory?: string;
}

export function Header (props: HeaderProps) {
  const { getSettings } = useSettings()

  const data = {
    vendor: getSettings.vendor,
    contract: getSettings.features.vendorBehavior
  }

  return (
    <Container>
      <TitleContainer>
        <Heading
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
          css={{ textTransform: 'capitalize' }}
          size="H2"
        >
          {props.pageTitle}
        </Heading>
        {props.pageCategory && (
          <Paragraph onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
            ({props.pageCategory})
          </Paragraph>
        )}
      </TitleContainer>
      <Dialog.Root
        title={
          <Heading onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} size="H2">
            Information
          </Heading>
        }
        trigger={<IconButton icon={() => <Info />} size="medium" />}
      >
        <Wrapper>
          {Object.keys(data).map((key) => (
            <TextRow key={key}>
              <Paragraph
                onPointerEnterCapture={undefined}
                onPointerLeaveCapture={undefined}
                css={{ fontWeight: 'bold', textTransform: 'uppercase' }}
                size="small"
              >
                {key}:
              </Paragraph>
              <Paragraph onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} size="small">
                {data[key as keyof typeof data]}
              </Paragraph>
            </TextRow>
          ))}
        </Wrapper>
      </Dialog.Root>
    </Container>
  )
}

const Container = styled('div', {
  display: 'flex',
  width: '100%',
  alignItems: 'center',
  justifyContent: 'space-between'
})

const Wrapper = styled('div', {
  marginTop: '$4',
  marginBottom: '$4'
})

const TextRow = styled('span', {
  display: 'flex',
  alignItems: 'center',
  marginBottom: '$1',
  ':first-child': {
    marginRight: '$2'
  }
})

const TitleContainer = styled('div', {
  display: 'flex',
  alignItems: 'end',

  p: {
    marginLeft: '$2',
    marginBottom: '2px',
    textTransform: 'capitalize',
    fontWeight: '$medium'
  }
})
