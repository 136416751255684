import { css } from '@hexa-ui/theme'

export const remove = css({
  '&::-webkit-scrollbar': {
    display: 'none'
  },

  '-ms-overflow-style': 'none',
  'scrollbar-width': 'none'
})
