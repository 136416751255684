import { IRepositoryRequirements } from 'repositories/repositories.common'
import { Fetch } from 'utils'
import { getCategoriesMapper } from './Product.mapper'
import {
  GetCategoriesProps,
  ProductAssociateBatchProps,
  ProductAssociateProps,
  ProductCreateProps,
  ProductCreateVariantsProps
} from './Product.props'

const ProductRepository = ({ baseUrl, authentication }: IRepositoryRequirements) => {
  const url = `${baseUrl}/products`

  const list = async () => {
    return Fetch.get({ url, config: { headers: authentication } })
  }

  const associate = async (data: ProductAssociateProps) => {
    return Fetch.post({ data, url: `${url}/accounts`, config: { headers: authentication } })
  }

  const associateBatch = async (data: ProductAssociateBatchProps) => {
    return Fetch.post({
      data,
      config: { headers: authentication },
      url: `${url}/accounts/batch`
    })
  }

  const create = async (data: ProductCreateProps) => {
    return Fetch.post({ url, data, config: { headers: authentication } })
  }

  const getCategories = async (data: GetCategoriesProps) => {
    return Fetch.get({
      url: `${url}/categories`,
      config: { data: { categoryId: data.categoryId }, headers: authentication }
    }).then(({ data }) => getCategoriesMapper(data.data))
  }

  const getStoreCategories = async (storeId: string) => {
    return Fetch.get({
      url: `${url}/categories/${storeId}`,
      config: { headers: authentication }
    }).then(({ data }) => getCategoriesMapper(data))
  }

  const createVariants = async (data: ProductCreateVariantsProps) => {
    return Fetch.post({ url: `${url}/variants`, data, config: { headers: authentication } })
  }

  return {
    list,
    associate,
    associateBatch,
    getCategories,
    create,
    createVariants,
    getStoreCategories
  }
}

export default ProductRepository
