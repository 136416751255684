import { IRepositoryRequirements } from 'repositories/repositories.common'
import { Fetch } from 'utils'
import { toSearchResult } from './Search.mapper'
import { SearchDTO } from './Search.props'

export function SearchRepository ({ baseUrl, authentication }: IRepositoryRequirements) {
  const url = `${baseUrl}/search`

  function searchData<T> (data: SearchDTO) {
    return Fetch.post({
      url: `${url}`,
      data,
      config: { headers: authentication }
    }).then(({ data }) => toSearchResult<T>(data))
  }

  return {
    searchData
  }
}
