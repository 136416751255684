/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import { ConfigsRepository, VendorRepository } from 'repositories'
import { SettingsProps } from './Settings.props'
import { SettingsReducer } from './Settings.reducer'
import { useEnvService } from 'admin-portal-shared-services'
import { useFeedbackDialog } from 'contexts'
import CountriesInfo from './_assets/countries.json'

const LOCAL_STORAGE_CACHE_KEY = 'data-generator/configs'
const LOCAL_STORAGE_CACHE_DATA = window.localStorage.getItem(LOCAL_STORAGE_CACHE_KEY)

const INITIAL_STATE: SettingsProps = LOCAL_STORAGE_CACHE_DATA ? JSON.parse(LOCAL_STORAGE_CACHE_DATA) : {}
let IS_COLD_START = !!INITIAL_STATE.features

export function SettingsBootstrap () {
  const [state, dispatch] = React.useReducer(SettingsReducer, INITIAL_STATE)
  const [status, setStatus] = React.useState<'LOADING' | 'LOADED' | 'ERROR'>(IS_COLD_START ? 'LOADED' : 'LOADING')

  const environment = useEnvService().getEnv() === 'UAT' ? 'UAT' : 'SIT'
  const baseUrl = `https://services-${environment}.bees-platform.dev/data-brew-server`

  const { getFeatures } = ConfigsRepository(baseUrl)
  const { getAll } = VendorRepository(baseUrl)
  const { requestError } = useFeedbackDialog()

  React.useLayoutEffect(() => {
    if (IS_COLD_START) return

    getAll()
      .then((vendors) => {
        const availableZones = CountriesInfo.filter((country) => Object.keys(vendors).includes(country.code)).map(
          (country) => ({ code: country.code, name: country.name, currencyCode: country.currency_code })
        )

        dispatch({ type: 'SET_AVAILABLES', payload: { availableVendors: vendors, availableZones } })
        dispatch({ type: 'SET_SETTINGS', payload: { environment, baseUrl, zone: availableZones[0].code } })
      })
      .catch((error) => {
        setStatus('ERROR')
        requestError(error.response.data)
      })
  }, [])

  React.useEffect(() => {
    if (IS_COLD_START) return

    if (state.zone) {
      const defaultVendor = state.availableVendors[state.zone][0].value
      dispatch({ type: 'SET_SETTINGS', payload: { vendor: defaultVendor } })
    }
  }, [state.zone, state.availableZones])

  React.useEffect(() => {
    if (IS_COLD_START) return

    if (state.vendor) {
      setStatus('LOADING')
      getFeatures(state.zone, state.vendor)
        .then((features) => {
          dispatch({ type: 'SET_SETTINGS', payload: { features: features[state.zone] } })
          setStatus('LOADED')
        })
        .catch((error) => {
          setStatus('ERROR')
          requestError(error.response.data)
        })
    }
  }, [state.vendor])

  React.useEffect(() => {
    if (IS_COLD_START) {
      IS_COLD_START = false
      return
    }

    window.localStorage.setItem(LOCAL_STORAGE_CACHE_KEY, JSON.stringify(state))
  }, [state])

  return {
    state,
    dispatch,
    status
  }
}
