import { IRepositoryRequirements } from 'repositories/repositories.common'
import { Fetch } from 'utils'
import { RandomPopulatorCreateProps, ModularPopulatorCreateProps } from './Populator.props'
import { toModularPopulatorResult } from './Populator.mapper'

export function PopulatorRepository ({ baseUrl, authentication }: IRepositoryRequirements) {
  const url = `${baseUrl}/populator`

  function createRandom (data: RandomPopulatorCreateProps) {
    return Fetch.post({ url: `${url}/random/accounts`, data, config: { headers: authentication } })
  }

  function createModular (data: ModularPopulatorCreateProps) {
    return Fetch.post({ url: `${url}/modular`, data, config: { headers: authentication } }).then(({ data }) =>
      toModularPopulatorResult(data)
    )
  }

  return {
    createRandom,
    createModular
  }
}
