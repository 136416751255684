import { useContext } from 'react'
import { FeedbackDialogContext } from './FeedbackDialog.provider'
import { genid } from 'utils'

export const useFeedbackDialog = () => {
  const { state, dispatch } = useContext(FeedbackDialogContext)

  function clearAll () {
    dispatch({
      type: 'CLOSE_ALL',
      payload: undefined
    })
  }

  function onClose (id: string | number) {
    dispatch({ type: 'REM_DIALOG', payload: id })
  }

  function refreshPage () {
    window.location.href = window.location.href + '?reload=' + Math.random()
  }

  function requestError (ErrorPayload: any, requestTraceId?: string) {
    type ErrorType = 'instability' | 'external' | 'general'

    const data = ErrorPayload.externalRequestData ?? ErrorPayload
    const { statusCode, path, message, responseData } = data
    const jsonViewData = typeof responseData === 'object' ? responseData : null

    const defineErrorType = (): ErrorType => {
      if (ErrorPayload.externalRequestData?.message.includes('aborted')) return 'instability'
      if (ErrorPayload.externalRequestData) return 'external'
      else return 'general'
    }

    const ErrorMessage = {
      instability: {
        title: 'Please try again',
        msg: `Looks like an instability occurred on: ${path}`,
        alert: `${statusCode ? `[${statusCode}]` : '[500]'} ${message}`
      },
      external: {
        title: 'Error',
        msg: `Something went wrong with an external server on: ${path}`,
        alert: `${statusCode ? `[${statusCode}]` : '[500]'} ${message}`
      },
      general: {
        title: 'Error',
        msg: 'Something went wrong',
        alert: `[${statusCode}] ${message}`
      }
    }[defineErrorType()]

    dispatch({
      type: 'ADD_DIALOG',
      payload: {
        id: genid.generate(),
        title: ErrorMessage.title,
        message: ErrorMessage.msg,
        alertMessage: ErrorMessage.alert,
        alertType: 'error',
        moreInfoJson: jsonViewData,
        type: 'acknowledgement',
        requestTraceId,
        onClose
      }
    })
  }

  function requestTokenError (requestTraceId?: string) {
    const ErrorMessage = {
      title: 'Token Expired',
      msg: "Your token has expired, click “Ok” to refresh the page. \n If it doesn't work, try logging in again."
    }

    dispatch({
      type: 'ADD_DIALOG',
      payload: {
        id: genid.generate(),
        title: ErrorMessage.title,
        message: ErrorMessage.msg,
        type: 'acknowledgement',
        requestTraceId,
        onClose: refreshPage,
        showCloseIcon: false,
        closeText: 'Ok'
      }
    })
  }

  return {
    requestError,
    requestTokenError,
    clearAll,
    onClose,
    queue: state
  }
}
