import { SettingsProps, SettingsActions } from './Settings.props'

export function SettingsReducer (state: SettingsProps, action: SettingsActions) {
  switch (action.type) {
    case 'SET_SETTINGS':
      return { ...state, ...action.payload } as SettingsProps
    case 'SET_AVAILABLES':
      return { ...state, ...action.payload } as SettingsProps
    default:
      throw new Error('Invalid action type')
  }
}
