import { Fetch } from 'utils'
import { DeliveryCenterCreateProps } from './DeliveryCenter.props'
import { IRepositoryRequirements } from '../../repositories.common'
import { toCoverageAreasDTO } from './DeliveryCenter.mapper'

export function DeliveryCenterRepository ({ baseUrl, authentication }: IRepositoryRequirements) {
  const basePath = `${baseUrl}/delivery-centers`

  async function create (data: DeliveryCenterCreateProps) {
    return Fetch.post({ url: `${basePath}`, data, config: { headers: authentication } })
  }

  async function coverageAreas () {
    return Fetch.get({ url: `${basePath}/coverage-areas`, config: { headers: authentication } }).then(({ data }) =>
      toCoverageAreasDTO(data)
    )
  }

  return {
    create,
    coverageAreas
  }
}
