import React from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { useAnalyticsService } from 'admin-portal-shared-services'

import { SettingsProvider } from './Settings'
import { FeedbackDialogProvider } from './FeedbackDialog'
import { ThemeProvider } from './Theme'

interface GlobalProviderProps {
  segmentKey?: string;
  children: React.ReactElement;
}

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      retry: false
    }
  }
})

export function GlobalProvider (props: GlobalProviderProps) {
  const analyticsService = useAnalyticsService()

  React.useEffect(() => {
    if (!props.segmentKey) return

    analyticsService.load(props.segmentKey)
  }, [analyticsService, props.segmentKey])

  return (
    <ThemeProvider>
      <SettingsProvider onUpdate={() => queryClient.resetQueries()}>
        <QueryClientProvider client={queryClient}>
          <FeedbackDialogProvider>{props.children}</FeedbackDialogProvider>
        </QueryClientProvider>
      </SettingsProvider>
    </ThemeProvider>
  )
}
