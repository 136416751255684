export const mapVendorGetByZone = (data: any): string[] =>
  data.map((item: any) => ({ vendorId: item.vendorId, zone: item.zone }))

export function mapVendorGetAll (data: any[]): { [zone: string]: Array<{ name: string; value: string }> } {
  const datas = data.reduce((prev, item: any) => {
    const createdZones = Object.keys(prev)
    if (!createdZones.includes(item.zone)) {
      return {
        ...prev,
        [item.zone]: [{ name: item.name, value: item.vendorId }]
      }
    }
    return {
      ...prev,
      [item.zone]: [...prev[item.zone], { name: item.name, value: item.vendorId }]
    }
  }, {})

  return datas
}
