import { IRepositoryRequirements } from 'repositories/repositories.common'
import { CreateDTCOrdersProps, CreateOrdersProps } from './Orders.props'
import { Fetch, removeInvalidKeys } from 'utils'

export function OrdersRepository ({ baseUrl, authentication }: IRepositoryRequirements) {
  const basePath = `${baseUrl}/orders`

  async function create (payload: CreateOrdersProps) {
    return Fetch.post({
      url: `${basePath}/b2b`,
      data: removeInvalidKeys({ ...payload }),
      config: { headers: { ...authentication } }
    })
  }

  async function createDTC (payload: CreateDTCOrdersProps) {
    return Fetch.post({
      url: `${basePath}/dtc`,
      data: payload,
      config: { headers: { ...authentication } }
    })
  }

  return {
    create,
    createDTC
  }
}
