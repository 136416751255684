import { LoadingProps } from './Loading.props'
import { Heading, Paragraph, LoadingBuzz } from '@hexa-ui/components'
import * as Styled from './Loading.style'

const Loading = (props: LoadingProps) => {
  return (
    <Styled.Container fullHeight={props.fullHeight}>
      <LoadingBuzz />
      <Styled.TextContainer>
        {props.headerText && (
          <Heading onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} size="H4">
            {props.headerText}
          </Heading>
        )}
        {props.text && (
          <Paragraph onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
            {props.text}
          </Paragraph>
        )}
      </Styled.TextContainer>
    </Styled.Container>
  )
}

export default Loading
