import OpenInNew from '@mui/icons-material/OpenInNew'

export const OpenInNewIcon = (props: { onClick: () => void }) => (
  <OpenInNew
    {...props}
    style={{
      width: 13,
      height: 13,
      marginLeft: 2,
      color: '#555',
      position: 'relative',
      top: '-3px',
      cursor: 'pointer'
    }}
  />
)

export default OpenInNewIcon
