import { ReactElement } from 'react'
import { globalCss, ThemeProvider as HubThemeProvider } from '@hexa-ui/theme'

const GlobalStyles = globalCss({
  '*': {
    fontFamily: '$paragraph'
  }
})

const ThemeProvider = (props: { children: ReactElement }) => {
  GlobalStyles()
  return <HubThemeProvider theme="customer">{props.children}</HubThemeProvider>
}

export default ThemeProvider
