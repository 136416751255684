import { IRepositoryRequirements } from 'repositories/repositories.common'
import { Fetch } from 'utils'
import { CreateWishlistProps } from './Wishlist.props'

const WishlistRepository = ({ baseUrl, authentication }: IRepositoryRequirements) => {
  const url = `${baseUrl}/wishlist`

  const create = async (data: CreateWishlistProps) => {
    return Fetch.post({ url, data, config: { headers: authentication } })
  }

  return { create }
}

export default WishlistRepository
