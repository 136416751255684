import { Fetch } from 'utils'
import { mapAccountRead } from './Account.mapper'
import { AccountCreateProps, AccountReadProps } from './Account.props'
import { IRepositoryRequirements } from 'repositories/repositories.common'

export const AccountRepository = ({ baseUrl, authentication }: IRepositoryRequirements) => {
  const path = `${baseUrl}/accounts`

  async function create ({ credits, ...account }: AccountCreateProps) {
    return Fetch.post({
      url: path,
      data: { ...account, credit: credits },
      config: { headers: { ...authentication } }
    })
  }

  async function read ({ vendorAccountId }: AccountReadProps) {
    return await Fetch.get({ url: `${path}/${vendorAccountId}`, config: { headers: { ...authentication } } }).then(
      ({ data }) => mapAccountRead(data)
    )
  }

  return { create, read }
}

export default AccountRepository
