import { useState } from 'react'
import { AccordionProps } from './Accordion.props'
import { Heading, Paragraph, Tooltip } from '@hexa-ui/components'
import { OpenInNew } from 'assets/icons'
import * as Styled from './Accordion.style'

const Accordion = (props: AccordionProps) => {
  const [collapsed, setCollapsed] = useState<boolean>(props.disableCollapse ? true : props.collapsed || false)
  const dontRenderHeader = props.disableCollapse && !props.title

  function toggleCollapse () {
    if (!props.disableCollapse) setCollapsed((prev) => !prev)
  }

  function handleOpenInNew (url: string) {
    window.open(url, '_blank')
  }

  return (
    <Styled.Container data-testid="accordion">
      {!dontRenderHeader && (
        <Styled.Header data-testid="accordionHeader" disableClick={props.disableCollapse} onClick={toggleCollapse}>
          <Styled.TitleContainer>
            <Heading onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} size="H4">
              {props.title}
            </Heading>
            {props.description && (
              <Paragraph onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>
                ({props.description})
              </Paragraph>
            )}
            {props.openInNew && (
              <Tooltip text={props.openInNew!.tooltip}>
                <OpenInNew onClick={() => handleOpenInNew(props.openInNew!.url)} />
              </Tooltip>
            )}
          </Styled.TitleContainer>
          {!props.disableCollapse && <Styled.Icon open={collapsed} />}
        </Styled.Header>
      )}

      {collapsed && <Styled.Body data-testid="accordionBody">{props.children}</Styled.Body>}
    </Styled.Container>
  )
}

export default Accordion
