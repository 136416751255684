import { IRepositoryRequirements } from 'repositories/repositories.common'
import { Fetch } from 'utils'
import { RegisterEmptiesProps, ManagementEmptiesProps } from './Empties.props'

const EmptiesRepository = ({ baseUrl, authentication }: IRepositoryRequirements) => {
  const url = `${baseUrl}/empties`

  const register = async (data: RegisterEmptiesProps) => {
    return Fetch.post({
      url: `${url}/register`,
      data,
      config: { headers: authentication }
    })
  }

  const management = async (data: ManagementEmptiesProps) => {
    return Fetch.post({
      url: `${url}/management`,
      data,
      config: { headers: authentication }
    })
  }

  return { register, management }
}

export default EmptiesRepository
