import { IRepositoryRequirements } from 'repositories/repositories.common'
import { CreateComboForAccountProps, CreateComboForGddcProps } from './Combos.props'
import { Fetch, removeInvalidKeys } from 'utils'

export function CombosRepository ({ baseUrl, authentication }: IRepositoryRequirements) {
  const basePath = `${baseUrl}/combos`

  async function createForAccount (payload: CreateComboForAccountProps) {
    return Fetch.post({
      url: `${basePath}/contractful/account`,
      data: removeInvalidKeys({ ...payload }),
      config: { headers: { ...authentication } }
    })
  }

  async function createForGddc (payload: CreateComboForGddcProps) {
    return Fetch.post({
      url: `${basePath}/dtc/groupddc`,
      data: removeInvalidKeys({ ...payload }),
      config: { headers: { ...authentication } }
    })
  }

  return {
    createForAccount,
    createForGddc
  }
}
