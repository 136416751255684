/**
 * @description
 * removeInvalidKeys function is used to remove empty keys from a object
 *
 * @example
 * given
 *  name: 'my name',
 *  age: undefined,
 *  address: { street: 'Main Street', city: '', state: null  }
 *  cars: [{ name: '', model: '' }, { name: 'Ferrari', model: 'F40' }]
 *
 *
 * then
 * name: 'my name',
 * address: { street: 'Main Street' }
 * cars: [{ name: 'Ferrari', model: 'F40' }]
 *
 */
export default function removeInvalidKeys<T> (data: object | any[] | T): T | any {
  function newObjectWithoutKey (obj: object, key: string) {
    const newObj: any = { ...obj }
    delete newObj[key]
    return newObj
  }

  if (Array.isArray(data)) {
    // Remove chaves inválidas dos itens do array e remove objetos vazios
    const cleanedArray = data.map(removeInvalidKeys).filter((item) => {
      if (typeof item === 'object' && item !== null) {
        return Object.keys(item).length > 0 // mantém apenas objetos não vazios
      }
      return item !== '' && item !== null && item !== undefined && !Number.isNaN(item) // mantém itens válidos
    })

    return cleanedArray
  }

  if (typeof data === 'object' && data !== null) {
    return Object.entries(data).reduce((acc, [key, value]) => {
      const invalidValues = ['', null, undefined]

      // Verifica se o valor é inválido ou é NaN
      if (invalidValues.includes(value) || Number.isNaN(value)) {
        return newObjectWithoutKey(acc, key)
      }

      // Se o valor for um objeto ou array, processa-o recursivamente
      if (typeof value === 'object') {
        const cleanedValue = removeInvalidKeys(value)

        // Se o valor limpo for um objeto vazio, removê-lo
        if (typeof cleanedValue === 'object' && Object.keys(cleanedValue).length === 0) {
          return newObjectWithoutKey(acc, key)
        }

        return { ...acc, [key]: cleanedValue }
      }

      // Caso contrário, mantém o valor
      return { ...acc, [key]: value }
    }, {})
  }

  return data
}
