import { styled } from '@hexa-ui/theme'
import { Alert as HUBAlert } from '@hexa-ui/components'

export const Wrapper = styled('div', {
  '& > :not(:last-child)': {
    marginBottom: '$4'
  }
})

export const Container = styled('div', {
  backgroundColor: '$neutral0',
  borderRadius: '$4',
  boxShadow: '$4',
  padding: '$4 $8'
})

export const Alert = styled(HUBAlert, {
  width: '100% !important'
})

export const InputGroup = styled('div', {
  variants: {
    flex: {
      true: {
        display: 'flex',
        flexDirection: 'column',
        '> :not(:last-child)': {
          marginRight: '$4'
        },
        '@desktopSmall': {
          flexDirection: 'row'
        }
      },
      false: {
        display: 'grid',
        alignItems: 'end',
        gridTemplateColumns: 'repeat(1, 1fr)',
        rowGap: '$4',
        columnGap: '$4',
        '@desktopSmall': {
          gridTemplateColumns: 'repeat(4, 1fr)'
        },
        '> div': {
          width: '100% !important'
        }
      }
    }
  },

  defaultVariants: {
    flex: false
  }
})

export const InputGroup5Columns = styled('div', {
  display: 'grid',
  alignItems: 'end',
  gridTemplateColumns: 'repeat(1, 1fr)',
  rowGap: '$4',
  columnGap: '$4',
  '@desktopSmall': {
    gridTemplateColumns: 'repeat(5, 1fr)'
  },
  '> div': {
    width: '100% !important'
  }
})

export const Stack = styled('div', {
  '& > :not(:last-child)': {
    marginBottom: '$4'
  }
})

export const ButtonContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  margin: '1rem 0'
})

export const CenteredActionButton = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginTop: '2rem',

  '& button': {
    fontSize: '16px !important',
    border: '0 !important',
    padding: '0.5rem 1.0rem !important'
  }
})

export const InputListItem = styled('div', {
  marginBottom: '2rem'
})

export const SelectWrapper = styled('div')
