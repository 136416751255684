import { GetCatalogDTO } from './Catalogs.dto'

export function getCatalogMapper (data: any[]): GetCatalogDTO[] {
  return data.map((item: any) => ({
    id: item.id,
    vendorItemId: item.vendorItemId,
    name: item.name || '',
    itemId: item.itemId,
    returnable: item.returnable,
    inventory: item.inventory,
    enforcement: item.enforcement,
    priceInfo: item.priceInfo
  }))
}
