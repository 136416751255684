import { createContext, Dispatch, useReducer, ReactElement, ReactNode } from 'react'
import { FeedbackDialogActions, FeedbackDialogProps } from './FeedbackDialog.props'
import { FeedbackDialogReducer } from './FeedbackDialog.reducer'
import Dialog from './component/Dialog'

export const FeedbackDialogContext = createContext<{
  state: FeedbackDialogProps[];
  dispatch: Dispatch<FeedbackDialogActions>;
}>({ state: [] as FeedbackDialogProps[], dispatch: () => undefined })

export function FeedbackDialogProvider ({ children }: { children: ReactElement | ReactNode }) {
  const [state, dispatch] = useReducer(FeedbackDialogReducer, [])
  return (
    <FeedbackDialogContext.Provider value={{ state, dispatch }}>
      {children}
      <Dialog dialogs={state} />
    </FeedbackDialogContext.Provider>
  )
}
