import { useToast, TypeToast } from 'admin-portal-shared-services'

export type Action = {
  label: string;
  onClick: () => void;
}

export type Actions = [Action, Action?]

export function ActionTransform (Actions?: Actions) {
  if (!Actions) return undefined

  return Actions.map(
    (action) =>
      action && {
        name: action.label,
        action: action.onClick
      }
  ) as [any, any?]
}

export const useNotify = () => {
  const toastService = useToast()

  function alert (message: string, actions?: Actions) {
    toastService.notify({ type: TypeToast.INFO, message, actions: ActionTransform(actions) })
  }

  function error (message: string, actions?: Actions) {
    toastService.notify({ type: TypeToast.ERROR, message, actions: ActionTransform(actions) })
  }

  function success (message: string, actions?: Actions) {
    toastService.notify({ type: TypeToast.SUCCESS, message, actions: ActionTransform(actions) })
  }

  function warning (message: string, actions?: Actions) {
    toastService.notify({ type: TypeToast.WARNING, message, actions: ActionTransform(actions) })
  }

  return {
    alert,
    error,
    success,
    warning
  }
}
