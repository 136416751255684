import { Fetch } from 'utils'
import { mapVendorGetByZone, mapVendorGetAll } from './Vendor.mapper'

const VendorRepository = (baseUrl: string) => {
  const url = `${baseUrl}/vendors`

  const getByZone = async (zone: string) => {
    return Fetch.get({ url, config: { params: { zone } } }).then(({ data }) => mapVendorGetByZone(data))
  }

  const getAll = async () => {
    return Fetch.get({ url }).then(({ data }) => mapVendorGetAll(data))
  }

  return { getByZone, getAll }
}

export default VendorRepository
