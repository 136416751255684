import { Suspense } from 'react'
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'
import { LoadingDots } from '@hexa-ui/components'
import { Hub } from 'layouts'
import { useSettings } from 'contexts'
import { basename, Routes, Sidebar } from './Definitions'

export function Router () {
  const {
    getSettings: { features }
  } = useSettings()

  const Mount = {
    CONTRACTFUL: {
      modules: [
        Routes.ACCOUNT,
        Routes.PRODUCTS,
        Routes.PROMOTIONS,
        Routes.ORDERS,
        Routes.INVOICE,
        Routes.RECOMMENDATIONS,
        Routes.REWARDS,
        Routes.SUPPORT,
        Routes.DDC,
        Routes.SEARCH
      ],
      sidebar: [
        Sidebar.ACCOUNT,
        Sidebar.PRODUCTS,
        Sidebar.PROMOTIONS,
        Sidebar.ORDERS,
        Sidebar.INVOICE,
        Sidebar.RECOMMENDATIONS,
        Sidebar.REWARDS
      ],
      utils: [Sidebar.SEARCH, Sidebar.SUPPORT]
    },
    CONTRACTLESS: {
      modules: [
        Routes.PRODUCTS,
        Routes.ORDERS,
        Routes.RECOMMENDATIONS,
        Routes.SUPPORT,
        Routes.SEARCH
      ],
      sidebar: [Sidebar.PRODUCTS, Sidebar.ORDERS, Sidebar.RECOMMENDATIONS],
      utils: [Sidebar.SEARCH, Sidebar.SUPPORT]
    },
    DTC: {
      modules: [Routes.PRODUCTS, Routes.PROMOTIONS, Routes.ORDERS, Routes.SUPPORT, Routes.SEARCH],
      sidebar: [Sidebar.PRODUCTS, Sidebar.PROMOTIONS, Sidebar.ORDERS],
      utils: [Sidebar.SEARCH, Sidebar.SUPPORT]
    }
  }[features.vendorBehavior]

  if (features.emptiesType) {
    Mount.modules.push(Routes.EMPTIES)
    Mount.sidebar.push(Sidebar.EMPTIES)
  }

  if (features.upsertDeliveryCenterEnabled) {
    Mount.modules.push(Routes.DDC)
    // Mount.sidebar.push(Sidebar.DDC)
  }

  return (
    <BrowserRouter basename={basename}>
      <Hub sidebar={Mount.sidebar} utils={Mount.utils}>
        <Suspense fallback={<LoadingDots />}>
          <Switch>
            {Mount.modules.map(({ path, Element }) => (
              <Route key={path} path={path} component={Element} />
            ))}
            <Route exact path="/">
              <Redirect to={Mount.modules[0].path} />
            </Route>
            <Route path="*">
              <Redirect to={Mount.modules[0].path} />
            </Route>
          </Switch>
        </Suspense>
      </Hub>
    </BrowserRouter>
  )
}
