import { useContext } from 'react'
import { SettingsContext } from './Settings.provider'
import { SettingsPayload } from './Settings.props'

export const useSettings = () => {
  const { state, dispatch } = useContext(SettingsContext)

  function setSettings (payload: SettingsPayload) {
    dispatch({ type: 'SET_SETTINGS', payload })
  }

  return {
    setSettings,
    getSettings: {
      ...state,
      url: state.baseUrl
    }
  }
}

export default useSettings
