import { Fetch } from 'utils'
import { IRepositoryRequirements } from 'repositories/repositories.common'
import {
  AssociateGroupDDCProps,
  AssociateAccountProps,
  AssociateAccountRandomProps,
  AssociateDDCProps,
  GetCatalogProps
} from './Catalogs.props'
import { getCatalogMapper } from './Catalogs.mapper'

export function CatalogsRepository ({ baseUrl, authentication }: IRepositoryRequirements) {
  const basePath = `${baseUrl}/catalogs`

  function associateGroupDDC (data: AssociateGroupDDCProps) {
    return Fetch.post({
      data,
      url: `${basePath}/dtc/groupddc`,
      config: { headers: authentication }
    })
  }

  function associateAccount (data: AssociateAccountProps) {
    return Fetch.post({
      data,
      url: `${basePath}/contractful/account`,
      config: { headers: authentication }
    })
  }

  function associateAccountRandom (data: AssociateAccountRandomProps) {
    return Fetch.post({
      data,
      url: `${basePath}/contractful/account/random`,
      config: { headers: authentication }
    })
  }

  function associateDDC (data: AssociateDDCProps) {
    return Fetch.post({
      data,
      url: `${basePath}/contractless/ddc`,
      config: { headers: authentication }
    })
  }

  const getCatalog = async (data: GetCatalogProps) => {
    return Fetch.get({
      url: `${basePath}/${data.vendorAccountId}`,
      config: { headers: authentication }
    }).then(({ data }) => getCatalogMapper(data.products))
  }

  const getReturnableCatalog = async (data: GetCatalogProps) => {
    return Fetch.get({
      url: `${basePath}/${data.vendorAccountId}/returnables`,
      config: { headers: authentication }
    }).then(({ data }) => getCatalogMapper(data.products))
  }

  function updateStock (vendorAccountId: string) {
    return Fetch.post({
      data: {
        vendorAccountId
      },
      url: `${basePath}/update-stock`,
      config: { headers: authentication }
    })
  }

  return {
    associateGroupDDC,
    associateAccount,
    associateAccountRandom,
    associateDDC,
    getCatalog,
    getReturnableCatalog,
    updateStock
  }
}
